:root {
  --primary-action-color: #e66660;
  --primary-action-color-hover: #f39f9b;
  --secondary-action-color: #6bc0ab;
  --secondary-action-color-hover: #aed3ca;
  --background-non-primary: #1e1e1e;
  --cards-background: rgb(105, 105, 105, 0.2);
}
h1,
h2,
h3 {
  font-family: "Oswald", sans-serif;
}

body {
  background-color: var(--background-non-primary);
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.cookie-button-wrapper {
  padding: 0 20px;
}
